import {SeverityLevel} from '@sentry/types';

import appConfig from '../../config/appConfig';

import * as Crashlytics from './crashlytics';
import * as Sentry from './sentry';

export default {
  log: (message: string, severity: SeverityLevel = 'info'): void => {
    Crashlytics.log(message);
    Sentry.log(message, severity);
    devLog(message, severity);
  },
  recordError: (error: Error, jsErrorName?: string): void => {
    Crashlytics.recordError(error, jsErrorName);
    Sentry.recordError(error);
    devLog(error.toString(), 'error');
  },
};

const devLog = (message: string, severity: SeverityLevel): void => {
  if (appConfig.isProduction) {
    return;
  }

  switch (severity) {
    case 'fatal':
    case 'error':
      // eslint-disable-next-line no-console
      console.error(`[${severity}]`, message);
      return;

    case 'info':
      // eslint-disable-next-line no-console
      console.info(`[${severity}]`, message);
      return;

    case 'debug':
      // eslint-disable-next-line no-console
      console.debug(`[${severity}]`, message);
      return;

    case 'warning':
      // eslint-disable-next-line no-console
      console.warn(`[${severity}]`, message);
      return;

    case 'log':
    default:
      // eslint-disable-next-line no-console
      console.warn('[Log]', message);
      return;
  }
};
