import {SeverityLevel} from '@sentry/types';
import {produce} from 'immer';
import {DateTime} from 'luxon';
import {$CombinedState} from 'redux';

import type {RootState} from '@common/reducers';

import {DriverInfo} from '@services/pacoApi';

import appConfig from '../../../config/appConfig';

import * as Sentry from './sentrySDK';

const dsn = {
  production:
    'https://4493b5b8867b4382b35d301fd8bb2bef@o324944.ingest.sentry.io/5943159',
  developpement:
    'https://d35da5dbd5a54023ae8003642630db74@o324944.ingest.sentry.io/5944968',
};

export const reduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: RootState) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const copy: any = produce(state, draft => {
      delete draft.Planning.planningByDays;
      draft.Planning.markedDates = {};
      delete draft[$CombinedState];
      draft.Member.driverInfo = undefined as unknown as DriverInfo;
    });

    return flattenObject(copy);
  },
});

export const init = (): void => {
  Sentry.init({
    dsn: appConfig.isProduction ? dsn.production : dsn.developpement,
    environment: appConfig.appEnvName,
  });
};

export const log = (message: string, severity?: SeverityLevel): void => {
  Sentry.addBreadcrumb({
    type: 'log',
    level: severity,
    message,
    timestamp: DateTime.now().toMillis(),
  });
};

export const recordError = (error: Error): void => {
  Sentry.captureException(error);
};

export const crash = (): void => Sentry.nativeCrash();

const flattenObject = (ob: Record<string, unknown>) => {
  const result: Record<string, unknown> = {};

  for (const i in ob) {
    // eslint-disable-next-line no-prototype-builtins
    if (!ob.hasOwnProperty(i)) {
      continue;
    }

    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i] as Record<string, unknown>);
      for (const x in flatObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (!flatObject.hasOwnProperty(x)) {
          continue;
        }
        result[i + '.' + x] = flatObject[x];
      }
    } else {
      result[i] = ob[i];
    }
  }

  return result;
};
